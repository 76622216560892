import React from "react";
import Collapsible from "react-collapsible";

// data
import { team } from "./team";

// components
import Section from "../../components/Section";

const Team = () => {
  return (
    <Section id="team">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Our </span>Team
          </h2>
        </div>
        <div className="section-content">
          <div class="container">
            <div class="row justify-content-center">
              {team.map((member) => (
                <div class="col-12 col-md-4 my-3">
                  <div class="card border-0 shadow-lg pt-5 my-5 position-relative">
                    <div class="card-body p-4">
                      <div class="member-profile position-absolute w-100 text-center">
                        <img class="rounded-circle mx-auto d-inline-block shadow-sm" src={member.profileImg} alt="" />
                      </div>
                      <div class="card-text pt-1">
                        <h5 class="member-name mb-0 text-center text-primary font-weight-bold">{member.name}</h5>
                        <div class="mb-3 text-center">{member.title}</div>
                        <div class="text-center">
                          <Collapsible trigger={<button class="btn btn-sm btn-light">Read Story</button>}>
                            <div class="my-3">{member.description}</div>
                          </Collapsible>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer theme-bg-primary border-0 text-center">
                      <ul class="social-list list-inline mb-0 mx-auto">
                        <li class="list-inline-item">
                          <a class="text-dark" href={member.linkedin} target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-linkedin mr-4" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Team;
