import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

// assets
import logo from "../../assets/img/extropy_logo.svg";

// data
import { menu } from "../navigation";

const Nav = () => {
  const [navClass, setNavClass] = useState("");
  const [toggeledNav, settoggeledNav] = useState(false);

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let navClass = "";
      if (window.scrollY >= 200) {
        navClass = "scrolled";
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className="container">
        <a className="navbar-brand" href="#">
          <span>
            <img src={logo} alt="Extropy.io logo" style={{ width: 150 }} />
          </span>
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return "open";
            return "";
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>

        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return "show";
            return "";
          })()}`}
        >
          <ul className="navbar-nav ml-auto">
            {menu.map((item) => {
              if (item.hasOwnProperty("children")) {
                return <NavDropDown children={item.children} />;
              } else {
                return (
                  <li className="nav-item nav-link">
                    <NavHashLink to={"/#" + item.target} smooth={true} activeClassName="active">
                      {item.title}
                    </NavHashLink>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const NavDropDown = ({ children }) => {
  const [isOpen, toggle] = useState(false);

  return (
    <Dropdown isOpen={isOpen} toggle={() => toggle(!isOpen)}>
      <DropdownToggle style={{ backgroundColor: "inherit", border: "none" }} className="nav-item nav-link" caret>
        Discover
      </DropdownToggle>
      <DropdownMenu>
        {children.map((submenu) => {
          return (
            <RouterLink to={submenu.path}>
              <DropdownItem>{submenu.title}</DropdownItem>
            </RouterLink>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default Nav;
