import blogImage1 from "../../assets/img/blogImage1.jpg";
import blogImage2 from "../../assets/img/blogImage2.jpg";
import blogImage3 from "../../assets/img/blogImage3.jpg";

export const blogPosts = [
  {
    title: "Introduction to Auditing and our smart contract audit process",
    description:
      "In light of the recent $611 million exploit of PolyNetwork we present a short overview of smart contract auditing and explain our audit process. Extropy is working on tools to give more insight into exploits based on tokenomics, how these can be detected and avoided. …",
    url: "https://extropy-io.medium.com/introduction-to-auditing-and-our-smart-contract-audit-process-fa7aed2d5d3d",
    image: blogImage1,
  },
  {
    title: "Positive feedback from Float Protocol audit",
    description:
      "We recently provided a security audit for Float Protocol and received positive feedback from the team. Here’s a quick overview of their positive response...",
    url: "https://extropy-io.medium.com/positive-feedback-from-float-protocol-28c0d123f8c5",
    image: blogImage2,
  },
  {
    title: "UK Cryptocurrency and the Law: Part 3: AA v Persons Unknown (2019)",
    description:
      "Welcome to Part Three of our series showing how the UK legal system regards blockchain technology. In Part Three we look at recent case law.Welcome to Part Three of our series showing how the UK legal system regards blockchain technology.",
    url: "https://blog.extropy.io/posts/legal3.html",
    image: blogImage3,
  },
];
