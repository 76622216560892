import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Card, CardBody, Tooltip } from "reactstrap";

export default function ContentToCopy({ content }) {
  const [tooltipOpen, toggleTooltip] = useState(false);
  const [copiedText, setCopiedText] = useState("");

  return (
    <Card>
      <CardBody>
        <span style={{ textDecoration: "underline", color: "primary" }} id="TooltipExample">
          <CopyToClipboard text={content} onCopy={() => setCopiedText(content)}>
            <i class="fa fa-copy" aria-hidden="true">
              <code class="ml-3">{content}</code>
            </i>
          </CopyToClipboard>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target="TooltipExample"
          toggle={() => toggleTooltip(!tooltipOpen)}
        >
          {copiedText === content ? "copied to clipboard!" : "click to copy to clipboard"}
        </Tooltip>
      </CardBody>
    </Card>
  );
}
