import React from "react";

import minaLogo from "../../assets/img/partners/mina-logo.svg";

const MinaMonitoring = () => {
	return (
		<div style={{ padding: "10rem 5% " }}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<img
					src={minaLogo}
					alt="MINA logo"
					class="m-3"
					style={{ width: 50, float: "left" }}
				/>
				<h2>MINA Monitoring</h2>
			</div>
			<p>
				This page gives an overview of the performance of our node running on{" "}
				<a href="https://minaexplorer.com/">MINA network</a>
			</p>
			<iframe
				id="mina-monitoring-iframe"
				src="http://minamonitor.extropy.io:2222/"
				width="100%"
				height="800"
			/>
		</div>
	);
};

export default MinaMonitoring;
