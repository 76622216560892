import React from "react";

// assets
import bgImage from "../../assets/img/home_bg.jpg";

// components
import Section from "../../components/Section";
import Link from "../../components/Link";

const home = () => {
  return (
    <Section id="home">
      <div>
        <div className="home-content p-5" style={{ backgroundImage: `url(${bgImage})` }}>
          <div className="intro container text-center text-light">
            <h2 className="mb-4">Making Blockchain knowledge accessible.</h2>
            <h2 className="sub-title mb-4">Consultancy in Distributed Ledger Technology and Cryptography.</h2>
            <Link target="education" classes="btn btn-lg btn-primary mr-2">
              Learn More
            </Link>
            <Link target="contact" classes="btn btn-lg btn-light text-dark">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default home;
