export const menu = [
	{
		title: "Home",
		target: "home",
	},
	{
		title: "Services",
		target: "services",
	},
	{
		title: "Discover",
		children: [
			{
				title: "Proof of Kernel Work",
				path: "pokw",
			},
			{
				title: "MINA Monitoring",
				path: "mina-monitoring",
			},
			{
				title: "Extropy Projects",
				path: "projects",
			},
		],
	},
	{
		title: "Education",
		target: "education",
	},
	{
		title: "Clients",
		target: "clients",
	},
	{
		title: "Blog",
		target: "blog",
	},
	{
		title: "Team",
		target: "team",
	},
	{
		title: "Contact",
		target: "contact",
	},
];

export const socials = [
	{
		link: "https://twitter.com/extropy",
		icon: "fa-twitter",
	},
	{
		link: "https://www.linkedin.com/company/extropy-io-ltd/",
		icon: "fa-linkedin",
	},
	{
		link: "https://extropy-io.medium.com/",
		icon: "fa-medium",
	},
	{
		link: "https://t.me/joinchat/AAAAAE4-sCvat80zz8CntQ",
		icon: "fa-telegram",
	},
	{
		link: "https://github.com/ExtropyIO",
		icon: "fa-github",
	},
];
