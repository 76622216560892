import React from "react";

// data
import { blogPosts } from "./blog";

// components
import Section from "../../components/Section";

const Blog = () => {
  return (
    <Section id="blog">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Our </span>Blog
          </h2>
          <p className="section-subtitle mr-auto ml-auto">
            Read our latest blog posts about our previous audits, Defi and more.
          </p>
        </div>
        <div className="section-content">
          <div className="row">
            {blogPosts.map((post) => (
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img src={post.image} className="card-img-top" alt="Blog 1" />
                  <div className="card-body">
                    <h5 className="card-title">{post.title}</h5>
                    <p className="card-text">{post.description}</p>
                    <a href={post.url} className="btn btn-sm btn-primary" target="_blank" rel="noopener noreferrer">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Blog;
