import React from "react";
import PropTypes from "prop-types";

const Projects = (props) => {
	return (
		<div style={{ padding: "10rem 5% " }}>
			<h2>Projects</h2>
			<p>We are working on the following projects:</p>
			<ol>
				<li>
					<a href="">Custard Watchtower</a>
					<p>
						We also presented the project at Argent engineering presentation{" "}
						<a
							href="https://twitter.com/argentdeveloper/status/1684911446172516352"
							target="_blank"
						>
							Video
						</a>
					</p>
					<iframe
						width="480"
						height="300"
						src="https://www.youtube.com/embed/tpqfDdMK7Tw"
						title="Entry for Pragma Hackathon"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
					<p>
						View the code on{" "}
						<a
							href="https://github.com/ExtropyIO/Pragma-Oracle-Hackathon"
							target="_blank"
						>
							github.
						</a>
					</p>
				</li>
				<li>
					<a
						href="https://ethglobal.com/showcase/autonomous-learning-qmadw"
						target="_blank"
					>
						Autonomous Learning
					</a>
					<p>
						A canvas build to interact with <a href="https://mud.dev/">MUD </a>
						OPStack game engine.
					</p>
					<p>
						Images or points can be exported for training Machine Learning
						models which can be verified with Zero Knowledge Proofs developed by
						OxPARC's <a href="https://github.com/zkonduit/ezkl">EZKL</a>
					</p>
					<div>
						<iframe
							width="480"
							height="300"
							src="https://stream.mux.com/OQ402EjWRl02I55nC4pn7JLgkPR4e00GoNG1pGUCC5qVsc/high.mp4"
							title="Entry for Pragma Hackathon"
							frameborder="0"
							allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
							autoplay="false"
						></iframe>
					</div>
					<p>
						View the code on{" "}
						<a href="https://github.com/ExtropyIO/AWHack" target="_blank">
							github.
						</a>
					</p>
				</li>
				<li>
					<a
						href="https://0xtitans.com/0xMonaco/Battle0xTitans-Season-2/teams/EGY7sqU1tacaOMZmnPNa5XKvZ162"
						target="_blank"
					>
						0xTitans
					</a>
				</li>
				<li>
					<a href="https://www.solidityctf.xyz/" target="_blank">
						Solidity CTF
					</a>
				</li>
			</ol>
		</div>
	);
};

Projects.propTypes = {};

export default Projects;
