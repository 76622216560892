import React, { Fragment } from "react";

import Home from "./Home";
import Services from "./Services";
import Education from "./Education";
import Promo from "./Promo";
import Clients from "./Clients";
import Blog from "./Blog";
import Facts from "./Facts";
import Team from "./Team";
import Contact from "./Contact";

const sections = () => {
  return (
    <Fragment>
      <Home />
      <Services />
      <Promo />
      <Education />
      <Clients />
      <Blog />
      <Facts />
      <Team />
      <Contact />
    </Fragment>
  );
};

export default sections;
