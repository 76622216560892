import React from "react";

import { List, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody } from "reactstrap";

import ContentToCopy from "../../components/ContentToCopy";

import clorioWallet from "../../assets/img/clorio-mina-wallet.svg";

const MINA_STAKING_ADDRESS = "B62qov9yv8TayLteD6SDXvxyYtmn3KkUoozAbs47fVo9JZSpcynbzTz";

export const MinaModal = ({ modal, toggle }) => {
  return (
    <Modal fullscreen size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader
        close={
          <button className="close" onClick={toggle}>
            ×
          </button>
        }
      >
        How to stake MINA tokens with Extropy?
      </ModalHeader>
      <ModalBody>
        <div class="container mb-4">
          <h4>Staking Account</h4>
          <p>Use this address to stake your MINA tokens with Extropy.</p>
          <ContentToCopy content={MINA_STAKING_ADDRESS} />
        </div>
        <div class="container mb-4">
          <h4>Delegatee Details</h4>
          <ListGroup>
            <ListGroupItem>2% fee to cover uptime and server maintenance</ListGroupItem>
            <ListGroupItem>Payout each successful epoch (~2 weeks)</ListGroupItem>
            <ListGroupItem>As a small pool your proportion of the reward is higher</ListGroupItem>
            <ListGroupItem>Accessible support via our channels</ListGroupItem>
          </ListGroup>
        </div>
        <div class="container mb-4">
          <h4>Information</h4>
          <List type="unstyled">
            <li class="m-2">Our Mina nodes participate in consensus by generating zk-SNARKs.</li>
            <li class="m-2">Smaller pools run by these nodes helps decentralisation.</li>
            <li class="m-2">Increasing the stake improves the chance of getting a slot to generate a block.</li>
            <li class="m-2">Delegating Mina allows the benefits of staking without maintaining an always-on node.</li>
            <li class="m-2">Increasing the tokens in a pool increases the chances of winning a block reward.</li>
          </List>
          <a href="https://docs.minaprotocol.com/en/using-mina/staking" target="_blank" rel="noopener noreferrer">
            Read more on MINA website
          </a>
        </div>
        <div class="container mb-4">
          <h4>Delegating MINA</h4>
          <p>You have two main options to stake, via your Clorio wallet or the command line.</p>
          <div class="row">
            <div class="col-md-6">
              <h5>with Clor.io wallet</h5>
              <a href="https://clor.io/" target="_blank" rel="noopener noreferrer">
                <img src={clorioWallet} alt="Clorio Wallet logo" />
              </a>
              <p>Please follow the guide provided by Clorio, using our address or searching for 'Extropy'.</p>
              <a href="https://docs.clor.io/how-to-delegate" target="_blnk">
                Guide provided by Clor.io
              </a>
            </div>
            <div class="col-md-6">
              <h5>via Command Line</h5>
              <p>
                Run these commands, replacing <code>$MINA_PUBLIC_KEY</code> with your public key.
              </p>
              <div class="mb-3">
                <p>
                  <b>1. Ensure your account is unlocked</b>
                </p>
                <code>mina account unlock -public-key $MINA_PUBLIC_KEY</code>
              </div>
              <div class="mb-3">
                <p>
                  <b>2. Delegate stake by sending this transaction.</b>
                </p>
                <code>
                  mina client delegate-stake \ <br />
                  -receiver B62qov9yv8TayLteD6SDXvxyYtmn3KkUoozAbs47fVo9JZSpcynbzTz \ <br />
                  -sender $MINA_PUBLIC_KEY \ <br />
                  -fee 0.1
                </code>
              </div>
            </div>
            <p>
              <b>Note: There is a latency period of a 2-4 weeks before your new stake delegation comes into effect</b>
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
